// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OpenAI_contentColumnBorder__pSFrd {
  border-left: 1px solid #e0e0e0; }
`, "",{"version":3,"sources":["webpack://./src/Detailedview/OpenAI/OpenAI.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAA8B,EAAA","sourcesContent":[".contentColumnBorder {\n  border-left: 1px solid #e0e0e0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentColumnBorder": `OpenAI_contentColumnBorder__pSFrd`
};
export default ___CSS_LOADER_EXPORT___;
