import ConfigService from "./ConfigService";
import { PlantKpiInterface } from "../Config/interfaces/PlantInterface";
import { KpisConstant } from "../Config/constants/KpisConstant";

class PlantKpiService extends ConfigService {

  constructor() {
    super();
  }

  async analyzeChartData(formData: FormData | any): Promise<any> {
    try {
      const res = await fetch(this.serverBaseUrl + KpisConstant.analyzeChartDataApi, {
        method: "POST",
        body: formData,
        headers: typeof formData !== 'string' ? { "Authorization": "Bearer " + this.accessToken, "Accept": "application/json" } : this.headersList,
      });
      const reader = res.body!.getReader();
      const decoder = new TextDecoder('utf-8');
      let result = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        result += chunk;
      }
      return result;
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async nextConversation(text: string): Promise<any> {
    try {
      const res = await fetch(this.serverBaseUrl + KpisConstant.nextConversationApi, {
        method: "POST",
        body: JSON.stringify({text}),
        headers: this.headersList,
      });
      const reader = res.body!.getReader();
      const decoder = new TextDecoder('utf-8');
      let result = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        result += chunk;
      }
      return result;
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async getPlantKpi(selectedPlantId: number) {
    if (!selectedPlantId || isNaN(selectedPlantId)) {
      return [];
    }

    const url: string = this.serverBaseUrl + KpisConstant.getPlantKpisApi + selectedPlantId;
    try {
      const response = await fetch(url, {
        headers: this.headersList
      });
      return await response.json();
    } catch (err) {
      console.error('API call failed: ', err);
    }
  }

  async updateShownStatusOfKpi(isShown: boolean, plantId: string, kpiId: string | undefined) {
    if (!plantId && !kpiId) {
      return null;
    }
    const url: string = this.serverBaseUrl + KpisConstant.updateShownStatusOfKpiApi;
    const requestBody = { isShown, plantId, kpiId };

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: this.headersList,
        body: JSON.stringify(requestBody)
      });
      return await response.json();
    } catch (err) {
      console.error('API call failed: ', err);
    }
  }

  searchPlantKpis(searchText: string, plantKpis: PlantKpiInterface[] | []) {
    if (!searchText || !plantKpis.length) {
      return [];
    }

    const searchTextArr = searchText.trim().split(/ +/);

    return plantKpis.filter(kpi => {
      return searchTextArr.some(word => {
        return kpi?.IotData?.displayName?.toLowerCase()?.includes(word.toLowerCase())
      });
    });
  }

  filterKpis(plantKpis: PlantKpiInterface[], categoryId: number) {
    if (!plantKpis?.length || !categoryId) {
      return [];
    }

    return plantKpis.filter((kpi: any) => kpi['categoryId'] === categoryId && kpi.isShown);
  }
}

export default PlantKpiService;
