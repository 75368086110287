import {FormulaAllowedKeys, FormulaCharRemovalKeys, KpiFields, KpiInputTypesEnum} from "../enums/KpisEnum";
import {FieldsConfig, KpiFormInterface} from "../interfaces/KpiInterface";

export class KpisConstant {

    static readonly kpiRowNumber: number = 10;
    static readonly kpiHeading: string = 'KPI Configurator';
    static readonly kpiConfig: string = 'Configure KPIs';
    static readonly kpiAnalyticsButton: string = 'KPI Analytics';
    static readonly kpisNotFound: string = 'KPIs not found!';

    //toast messages
    static readonly kpiSaved = "KPIs saved successfully";
    static readonly kpiFailedToSave = "KPIs failed to save - Server encountered an internal error";
    static readonly incorrectPlantSelection = "KPIs failed to save - something went wrong with plant selection";
    static readonly kpiDeleted = 'Kpi deleted successfully';
    static readonly kpiCategoryAdded = 'added successfully';

    // APIs
    // KPIs form
    static readonly getKpiCategoriesApi: string = '/api/kpiCategories';
    static readonly addKpiCategoryApi: string = '/api/addKpiCategory';
    static readonly getKpisApi: string = '/api/kpis';
    static readonly saveKpisApi: string = '/api/saveKpis';
    static readonly deleteKpisApi: string = '/api/deleteKpi/';
    // Plant Kpis (Dashboard)
    static readonly updateShownStatusOfKpiApi: string = '/api/updateShownStatusOfKpi';
    static readonly getPlantKpisApi: string = '/api/getPlantKpis/';
    static readonly analyzeChartDataApi: string = '/api/analyzeChartData';
    static readonly nextConversationApi: string = '/api/nextConversation';
    // Formula Editor
    static readonly formulaRegex: RegExp = /^(?!\s)[0-9\s\-\/+*.%^(){}[\]]+$/;
    static readonly formulaDelimiterRegex: RegExp = /([+\-*/()%^{}[\]])/;
    static readonly formulaCharRemovalKeys: string[] = [FormulaCharRemovalKeys.Backspace, FormulaCharRemovalKeys.Delete];
    static readonly formulaAllowedKeys: string[] = [
        FormulaAllowedKeys.ArrowLeft,
        FormulaAllowedKeys.ArrowRight,
        FormulaAllowedKeys.ArrowUp,
        FormulaAllowedKeys.ArrowDown,
        FormulaAllowedKeys.Home,
        FormulaAllowedKeys.End
    ];
    static readonly formulaEditorPlaceholder: string = 'Please use only one tag.';
    static readonly formulaEditorFormulaBasedPlaceholder: string = 'Only digits and the following characters are allowed with tags: +, -, *, /, (, ), %, ^';

    // Supported input types
    static readonly textInputTypes: KpiInputTypesEnum[] = [KpiInputTypesEnum.hidden, KpiInputTypesEnum.text, KpiInputTypesEnum.number];
    static readonly selectType: KpiInputTypesEnum[] = [KpiInputTypesEnum.select];
    static readonly textareaType: KpiInputTypesEnum[] = [KpiInputTypesEnum.textarea];
    static readonly checkboxType: KpiInputTypesEnum[] = [KpiInputTypesEnum.checkbox];

    // It is used for table columns' title // TODO: Info: Maintain add / remove fields here
    static readonly fieldsTitle: { [key: string]: string } = {
        [KpiFields.id]: 'KPI Id',
        [KpiFields.name]: 'Name',
        [KpiFields.formulaBased]: 'Formula based',
        [KpiFields.formula]: 'Formula',
        [KpiFields.categoryId]: 'Category',
        [KpiFields.lowerLimit]: 'Min',
        [KpiFields.upperLimit]: 'Max',
        [KpiFields.measurement]: 'Unit',
        [KpiFields.description]: 'Description'
    };

    // TODO - Info: Add / Remove fields from here (Maintain interface, enum and constants)
    // For Select box - set options dynamically in the selectOption key
    static readonly fieldsConfig: FieldsConfig = {
        [KpiFields.id]: {
            title: this.fieldsTitle[KpiFields.id],
            type: KpiInputTypesEnum.hidden,
            isHidden: true,
            readonly: false,
            defaultValue: '',
            placeholder: '',
            rules: {required: false}
        },
        [KpiFields.name]: {
            title: this.fieldsTitle[KpiFields.name],
            type: KpiInputTypesEnum.text,
            isHidden: false,
            readonly: false,
            defaultValue: '',
            placeholder: 'KPI name',
            rules: {required: true}
        },
        [KpiFields.formulaBased]: {
            title: this.fieldsTitle[KpiFields.formulaBased],
            type: KpiInputTypesEnum.hidden,
            isHidden: true,
            readonly: false,
            defaultValue: 'false',
            placeholder: '',
            rules: {required: false}
        },
        [KpiFields.formula]: {
            title: this.fieldsTitle[KpiFields.formula],
            type: KpiInputTypesEnum.textarea,
            isHidden: false,
            readonly: true,
            defaultValue: '',
            placeholder: 'Formula',
            rules: {required: true}
        },
        [KpiFields.categoryId]: {
            title: this.fieldsTitle[KpiFields.categoryId],
            type: KpiInputTypesEnum.select,
            isHidden: false,
            readonly: false,
            defaultValue: '',
            placeholder: 'Please select category',
            rules: {required: true},
            editable: true,
            placeholderEditable: 'Add new category',
            selectMode: 'single',
            selectOptions: [] // will be set dynamically
        },
        [KpiFields.lowerLimit]: {
            title: this.fieldsTitle[KpiFields.lowerLimit],
            type: KpiInputTypesEnum.number,
            isHidden: false,
            readonly: false,
            defaultValue: '',
            placeholder: 'Min',
            rules: {required: false}
        },
        [KpiFields.upperLimit]: {
            title: this.fieldsTitle[KpiFields.upperLimit],
            type: KpiInputTypesEnum.number,
            isHidden: false,
            readonly: false,
            defaultValue: '',
            placeholder: 'Max',
            rules: {required: false}
        },
        [KpiFields.measurement]: {
            title: this.fieldsTitle[KpiFields.measurement],
            type: KpiInputTypesEnum.text,
            isHidden: false,
            readonly: false,
            defaultValue: '',
            placeholder: 'Units',
            rules: {required: false},
            maxlength: 10
        },
        [KpiFields.description]: {
            title: this.fieldsTitle[KpiFields.description],
            type: KpiInputTypesEnum.textarea,
            isHidden: false,
            readonly: false,
            defaultValue: '',
            placeholder: 'Description',
            rules: {required: false}
        }
    };

    static readonly initialValues: KpiFormInterface = KpisConstant.getInitialValues();

    private static getInitialValues(): KpiFormInterface {
        const fields: any = {};
        Object.keys(KpisConstant.fieldsConfig).forEach(field => {
            fields[field] = KpisConstant.fieldsConfig[field].defaultValue;
        });

        return fields;
    }
}