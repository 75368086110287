import { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import KeyCloakService from "./KeycloakService";
import { useInject } from "../hooks/useInject";
import UsersService from "../Services/UsersService";
import RolesService from "../Services/RolesService";
import PlantService from "../Services/PlantService";
import { useAppDispatch } from "../redux/hooks";
import { setRealmId } from "../redux/slices/keycloakSlice";
import { setUsers, setUsersProfile } from "../redux/slices/userSlice";
import { setPlants, setOrgTree } from "../redux/slices/plantSlice";
import useGetKpisInitialData from "../hooks/useGetKpisInitialData";
import { RoleInterface, RolesActionInterface } from "../Config/interfaces/RoleInterface";
import { setLoggedInUserPermissions, setRoles } from "../redux/slices/roleSlice";
import { ManagementType, OrgManagementActions, PlantManagementActions, RoleManagementActions, UserManagementActions } from "../Config/enums/RolesEnum";

const Service = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const keycloakURL = process.env.REACT_APP_KEYCLOAK_BASEURL;
  const plantService = useInject(PlantService);
  const usersService = useInject(UsersService);
  const rolesService = useInject(RolesService);
  const [access_token] = useState(KeyCloakService.getToken());
  const loggedInUserRole = rolesService.getLoggedInUserRole();

  useGetKpisInitialData();

  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    fetch(keycloakURL + "clients?clientId=stp-app", {
      method: "GET",
      headers: headersList,
    })
      .then((res) => res.json())
      .then((data) => {
        const realmIdTemp = data?.[0]?.id ?? "";
        dispatch(setRealmId(realmIdTemp));
        getUsersProfileList(realmIdTemp).then((r) => r);
        getRoles(realmIdTemp).then((r) => r);
      });

    plantService.getPlants().then((plants: any) => {
      plants?.length ? dispatch(setPlants(plants)) : dispatch(setPlants([]));
    });

    plantService.getOrgHierarchy().then((orgTree: any) => {
      orgTree?.length
        ? dispatch(setOrgTree(orgTree))
        : dispatch(setOrgTree([]));
    });

    usersService.getUsersList().then((users: any) => {
      users?.length ? dispatch(setUsers(users)) : dispatch(setUsers([]));
    });
  }, []);

  async function getRoles(realmId: string) {
    if (!realmId) {
      return;
    }
    const roles: RoleInterface[] = await rolesService.bindRolePermissions(
      realmId,
      await rolesService.getRoles(realmId)
    );
    roles?.length ? dispatch(setRoles(roles)) : dispatch(setRoles([]));
    setPermissionsForLoggedInUser(roles);
  }

  function setPermissionsForLoggedInUser(roles: RoleInterface[]) {
    let permissions: RolesActionInterface | any = {
      [ManagementType.Org]: {
        [OrgManagementActions.Add]: false,
        [OrgManagementActions.View]: false,
        [OrgManagementActions.Del]: false,
        [OrgManagementActions.Full]: false
      },
      [ManagementType.User]: {
        [UserManagementActions.Add]: false,
        [UserManagementActions.View]: false,
        [UserManagementActions.Del]: false,
        [UserManagementActions.Full]: false
      },
      [ManagementType.Roles]: {
        [RoleManagementActions.Add]: false,
        [RoleManagementActions.View]: false,
        [RoleManagementActions.Del]: false,
        [RoleManagementActions.Full]: false
      },
      [ManagementType.Plant]: {
        [PlantManagementActions.Add]: false,
        [PlantManagementActions.View]: false,
        [PlantManagementActions.Del]: false,
        [PlantManagementActions.Full]: false
      }
    };
    roles.forEach((role) => {
      if (loggedInUserRole.includes(role.name)) {
        Object.keys(role.permissions).forEach((key) => {
          Object.keys((role.permissions as any)[key]).forEach((action) => {
            const permValue = (role.permissions as any)[key][action];
            if (!permissions[key][action] && permValue) {
              permissions[key][action] = permValue;
            }
          });
        });
      }
    });
    dispatch(setLoggedInUserPermissions(permissions));
  }

  async function getUsersProfileList(realmId: string) {
    if (!realmId) {
      return;
    }
    const usersProfile = await usersService.getUsersProfile(realmId);
    usersProfile.length
      ? dispatch(setUsersProfile(usersProfile))
      : dispatch(setUsersProfile([]));
  }

  return (
    <Layout />
  );
};

export default Service;
