import Routes from './Routes';
import '@siemens/ix-icons/dist/css/ix-icons.css';
import '@siemens/ix/dist/siemens-ix/siemens-ix.css';
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useInject } from '../hooks/useInject';
import SettingsService from '../Services/SettingsService';
import { setAutoDeletionEnabled, setAutoDeletionInterval, setOldestTimestamp, setSelectedPlants, setTheme } from '../redux/slices/settingsSlice';
import { showToast } from '@siemens/ix-react';
import { ToastType } from '../Config/constants/ToastMessage';
import { isAfter, subtractFromDate } from '../utilities/converters';

const App = () => {
  let currentTheme = localStorage.getItem('theme');
  const settingsService = useInject(SettingsService);
  const settingsSlice = useAppSelector(state => state.settingsSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dataDeletionHandler();
  }, [settingsSlice.autoDeletionEnabled]);

  useEffect(() => {
    updateTheme();
  }, [currentTheme]);

  useEffect(() => {
    getSettingsData();
  }, []);

  function updateTheme() {
    const themeClass = currentTheme === 'dark' ? 'theme-classic-dark' : 'theme-classic-light';
    if (themeClass === 'theme-classic-dark') {
      document.body.classList.remove('theme-classic-light');
    } else {
      document.body.classList.remove('theme-classic-dark');
    }
    document.body.classList.add(themeClass);
  }

  async function dataDeletionHandler() {
    if (settingsSlice.autoDeletionEnabled && settingsSlice.selectedPlants.length > 0) {
      const interval = setInterval(async () => {
        const plantIds = settingsSlice.selectedPlants.map((plant) => plant.SuborgId);
        let end = subtractFromDate(new Date(), settingsSlice.autoDeletionInterval, 'months').toISOString().split('T')[0];
        if (isAfter(new Date(settingsSlice.oldestTimestamp), new Date(end))) {
          end = settingsSlice.oldestTimestamp.split(' ')[0];
        }
        const settings = { plantIds, end: end + ' 23:59:59' };
        try {
          const data = await settingsService.deleteData(settings);
          if (data.status === 'success') {
            showToast({
              type: data.rowsDeleted ? ToastType.success : ToastType.error,
              title: 'Auto-data deletion',
              message: data.rowsDeleted ? `${data.rowsDeleted} rows deleted via Auto-data deletion` : 'No rows deleted',
            });
          } else {
            console.error('Deletion history is null or undefined');
          }
        } catch (error) {
          console.error('Failed to fetch deletion history:', error);
        }
      }, 24 * 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }

  async function getSettingsData() {
    try {
      const response = await settingsService.getSettings();
      const data = await response.json();
      if (data) {
        if (!currentTheme) {
          currentTheme = data.Theme;
          dispatch(setTheme(data.Theme));
        }
        dispatch(setOldestTimestamp(`${data.StartDate.split('T')[0]} ${data.StartDate.split('T')[1].split('.')[0]}`));
        dispatch(setAutoDeletionEnabled(data.AutoDeletionEnabled));
        dispatch(setAutoDeletionInterval(data.AutoDeletionInterval));
        dispatch(setSelectedPlants(data.SelectedPlants));
      } else {
        console.error('Settings data not available');
      }
    } catch (error) {
      console.error('Failed to fetch settings data:', error);
    }
  }

  return (
    <>
      <Routes />
    </>
  )
};
export default App;