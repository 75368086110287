import { useEffect, useRef, useState } from "react";
import ListNavigation from "./Listnavigation/Listnavigation";
import Card from "../Detailedview/Card/Card";
import styles from "./Detailedview.module.scss";
import { useParams } from "react-router-dom";
import Tabulardata from "./Tabulardata/Tabulardata";
import { jsPDF } from "jspdf";
import { CSVLink } from "react-csv";
import autoTable from "jspdf-autotable";
import {
	IxSplitButton,
	IxSplitButtonItem,
	IxExpandingSearch,
	IxInputGroup,
	showToast,
	IxIconButton,
	IxIcon,
	IxDrawer,
	IxDivider,
} from "@siemens/ix-react";
import { getFormattedStartEndDatesTimestamp } from "../utilities/converters";
import DateTimePickerInput from "../Shared/UI-Elements/DateTimePickerInput/DateTimePickerInput";
import { compareStartAndEndDate } from "../utilities/utils";
import { useToastPosition } from "../hooks/useToastPosition";
import { useAppSelector } from "../redux/hooks";
import TimeSeriesChart from "../Analytics/Charts/timeSeriesChart";
import { ToastTitle, ToastType } from "../Config/constants/ToastMessage";
import { initialStatData } from "../Config/constants/detailedViewConstant";
import { useInject } from "../hooks/useInject";
import PlantKpiService from "../Services/PlantKpiService";
import { PlantKpiInterface } from "../Config/interfaces/PlantInterface";
import OpenAIAnalyzer from "./OpenAI/OpenAI";
import ReactMarkdown from 'react-markdown';

interface ChatMessage {
	sender: string;
	message: string;
}

const Detailedview = (): JSX.Element => {
	const plantKpiService = useInject(PlantKpiService);
	const plantKpis = useAppSelector((state) => state.plantSlice.plantKpis);
	const [isLiveFeed, setIsLiveFeed] = useState(true);
	const [filteredKpis, setFilteredKpis] = useState<PlantKpiInterface[] | []>([]);
	const [isDrawerOpen, setDrawerOpen] = useState(false);
	const [userQuery, setUserQuery] = useState('');
	const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
	const chatHistoryRef = useRef<HTMLDivElement>(null);
	const [graphView, setGraphView] = useState(true);
	const [summaryView, setSummaryView] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [enableDatePicker, setEnableDatePicker] = useState(false);
	const [startDateTime, setStartDateTime] = useState({ date: "", time: "00:00:00" });
	const [endDateTime, setEndDateTime] = useState({ date: "", time: "23:59:59" });
	const [initialChartData, setInitialChartData]: any = useState([]);
	const [prevTimestamp, setPrevTimestamp]: any = useState("");
	const [statData, setStatData]: any = useState(initialStatData);
	const [tabularData, setTabularData]: any = useState([]);
	const LineChartRef: any = useRef(null);
	const abortConRef: any = useRef();
	const selectedKpiId = useAppSelector((state) => state.plantSlice.selectedKpiId);
	const selectedCategoryId = useAppSelector((state) => state.plantSlice.selectedCategoryId);
	const kpisForecastData = useAppSelector((state) => state.plantSlice.kpisForecastData);

	useToastPosition("top-right");

	useEffect(() => {
		filterKpis();
	}, [plantKpis, selectedCategoryId]);

	useEffect(() => {
		setTimeout(() => {
			initializeChart().then((r) => r);
			loadHistoricalData();
		});
	}, [selectedKpiId, selectedCategoryId]);

	useEffect(() => {
		appendChartData();
	}, [plantKpis]);

	useEffect(() => {
		addForecastData();
	}, [kpisForecastData]);

	useEffect(() => {
		//skip first call
		if (!filteredKpis?.length) {
			return;
		}
		if (graphView && isLiveFeed) {
			initializeChart().then((r) => r);
		} else if (!isLiveFeed && startDateTime.date !== "" && endDateTime.date !== "") {
			loadHistoricalData();
		}
	}, [graphView, isLiveFeed]);

	useEffect(() => {
		const startEndDateTimestamps = getFormattedStartEndDatesTimestamp(startDateTime, endDateTime);
		if (!startEndDateTimestamps) {
			return;
		}

		if (!compareStartAndEndDate(startEndDateTimestamps)) {
			// getDate(startEndDateTimestamps).then((r) => r);
			return;
		}
		if (isLiveFeed) {
			return;
		}
		fetchHistoricalData(startEndDateTimestamps);
	}, [startDateTime, endDateTime]);

	useEffect(() => {
		// Auto-scroll to the bottom of the chat history when a new message is added
		if (chatHistoryRef.current) {
			chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
		}
	}, [chatHistory]);

	const handleSendQuery = async () => {
		if (userQuery.trim()) {
			// Add user query to chat history
			setChatHistory([...chatHistory, { sender: 'You', message: userQuery }]);
			setUserQuery('');

			// Simulate system response (replace with actual API call)
			const systemResponse = await simulateSystemResponse(userQuery);
			setChatHistory([...chatHistory, { sender: 'You', message: userQuery }, { sender: 'AI Assistant', message: systemResponse as string }]);
		}
	};

	const simulateSystemResponse = async (query: string) => {
		// Simulate a delay for system response
		return await plantKpiService.nextConversation(query);
	};

	const handleKeyPress = (e: any) => {
		e.stopPropagation();
		if (e.key === 'Enter') {
			handleSendQuery();
		}
	};

	const filterKpis = () => {
		const plantKpisFiltered = plantKpiService.filterKpis(plantKpis, selectedCategoryId);
		setFilteredKpis(plantKpisFiltered);
	};

	const initializeChart = async (data: any = null) => {
		if (!selectedKpiId) {
			return;
		}

		const kpiData = getSelectedKpiInfo();
		LineChartRef.current?.load();
		if (!Object.keys(kpiData).length) {
			LineChartRef.current?.stopLoading();
			return false;
		}

		const payload = prepareChartPayload(data, kpiData);

		if (payload?.[0]?.data.length) {
			setTabularData(payload[0].data);
		}

		await setInitialChartData(() => payload);

		setTimeout(() => {
			LineChartRef.current?.reset(payload);
		});
	};

	const prepareChartPayload = (data: any = null, kpiData: PlantKpiInterface) => {
		const selectedKpiName = kpiData.IotData.displayName ?? "KPI";
		const markLine: any = {};
		if (kpiData.hasOwnProperty("lowerLimit")) {
			markLine["Lower limit"] = kpiData.lowerLimit;
		}
		if (kpiData.hasOwnProperty("upperLimit")) {
			markLine["Upper limit"] = kpiData.upperLimit;
		}
		const [timeData, values] = [kpiData.IotData.time, kpiData.timeseries];
		const dataset = data?.length
			? data
			: timeData.map((time: any, i: number) => {
				return [time, values[i]];
			});

		const actualPayload = {
			name: selectedKpiName,
			data: [...dataset],
			markLine,
		};

		if (isLiveFeed) {
			setStatData({
				count: 0,
				sum: 0,
				max: "NA",
				min: "NA",
				avg: 0,
				lowerLimit: kpiData?.lowerLimit,
				upperLimit: kpiData?.upperLimit,
				timeseries: kpiData?.timeseries ?? [],
			});
		}

		const forecastData = kpisForecastData?.[selectedKpiId] ?? [];

		if (!forecastData.length && isLiveFeed) {
			notifyUser(ToastType.warning, `Forecast data not found for "${selectedKpiName}"`);
		}

		if (forecastData?.length && isLiveFeed) {
			const forecastPayload = {
				name: selectedKpiName + "-Forecasted",
				data: forecastData.map((forecast) => [forecast.timestamp, forecast.value]) ?? [],
			};
			return [actualPayload, forecastPayload];
		} else {
			return [actualPayload];
		}
	};

	const appendChartData = () => {
		if (!filteredKpis?.length || !isLiveFeed) {
			return;
		}

		const currentTag = getSelectedKpiInfo();
		if (Object.keys(currentTag).length && isLiveFeed) {
			setStatData({
				count: 0,
				sum: 0,
				max: "NA",
				min: "NA",
				avg: 0,
				lowerLimit: currentTag?.lowerLimit,
				upperLimit: currentTag?.upperLimit,
				timeseries: currentTag?.timeseries ?? [],
			});

			const currentTimestamp = currentTag.IotData.currentTimestamp;

			const [timeData, values] = [currentTag.IotData.time, currentTag.timeseries];
			const dataset = timeData.map((time: any, i: number) => {
				return [time, values[i]];
			});

			setTabularData(dataset);

			if (currentTimestamp) {
				setPrevTimestamp(currentTimestamp);
			}
			if (!prevTimestamp) {
				initializeChart().then((r) => r);
			} else if (prevTimestamp && currentTimestamp && prevTimestamp !== currentTimestamp) {
				const payload = {
					name: currentTag.IotData.displayName,
					data: [currentTimestamp, currentTag.IotData.value],
				};
				LineChartRef.current?.add(payload.name, payload.data);
				setPrevTimestamp(currentTimestamp);
			}
		}
	};

	const addForecastData = async () => {
		if (!Object.keys(kpisForecastData).length || !isLiveFeed || !LineChartRef?.current) {
			return;
		}

		const selectedKpiInfo = getSelectedKpiInfo();
		if (!Object.keys(selectedKpiInfo)) {
			return;
		}

		const selectedKpiName = selectedKpiInfo?.IotData?.displayName ?? "";

		const forecastData = kpisForecastData[selectedKpiId] ?? [];
		if (!forecastData.length) {
			notifyUser(ToastType.warning, `Forecast data not found for "${selectedKpiName}"`);
			return;
		}

		const forecastPayload = forecastData.map((forecast) => [forecast.timestamp, forecast.value]);

		LineChartRef?.current?.addSeries(`${selectedKpiName}-Forecasted`, forecastPayload);
	};

	const getSelectedKpiInfo = (): PlantKpiInterface => {
		return plantKpis.filter((twq: any) => twq["id"] === selectedKpiId)?.[0] ?? {};
	};

	const notifyUser = (type: ToastType | any, message: string) => {
		showToast({
			type: type,
			title: ToastTitle[type as keyof typeof ToastTitle],
			message,
		}).then((r) => r);
	};

	const fetchHistoricalData = async (dateRange: any) => {
		if (Array.isArray(dateRange) && dateRange?.length !== 2) {
			return;
		}

		const currentTag = getSelectedKpiInfo();
		if (!Object.keys(currentTag).length) {
			return;
		}

		LineChartRef.current?.load();

		const [startDate, endDate] = dateRange;
		const kpiId = currentTag?.id ?? "";

		try {
			if (abortConRef.current) {
				abortConRef?.current?.abort();
			}
			abortConRef.current = new AbortController();
			const response = await fetch(
				`${plantKpiService.serverBaseUrl}/api/historicalData?` +
				new URLSearchParams({
					startDate,
					endDate,
					kpiId,
				}),
				{ signal: abortConRef.current.signal, headers: plantKpiService.headersList }
			);

			const result = await response.json();

			if (result?.count) {
				setStatData({
					count: result.count,
					sum: result.sum,
					max: result.max,
					min: result.min,
					avg: result.avg,
					lowerLimit: currentTag?.lowerLimit ?? "NA",
					upperLimit: currentTag?.upperLimit ?? "NA",
					timeseries: [],
				});
				initializeChart(result.data);
				setTabularData(result.data);
			} else {
				LineChartRef.current?.stopLoading();
			}
		} catch (err) {
			console.log("Could not retrieve messages", err);
		}
	};

	const loadHistoricalData = () => {
		if (isLiveFeed || !selectedKpiId) {
			return;
		}

		const startEndDateTimestamps = getFormattedStartEndDatesTimestamp(startDateTime, endDateTime);
		fetchHistoricalData(startEndDateTimestamps);
	};

	let { id }: any = useParams();
	let selectedParameterIndex: number = -1;

	if (!filteredKpis) {
		return null as any;
	}
	for (let i = 0; i < filteredKpis.length; i++) {
		if (filteredKpis[i]["id"] == id) {
			selectedParameterIndex = i;
			break;
		}
	}
	if (selectedParameterIndex === -1) {
		return null as any;
	}
	let arrayflat = [
		[filteredKpis[selectedParameterIndex].IotData.time],
		[filteredKpis[selectedParameterIndex].timeseries],
	].flat();
	//Convert rows to columns
	var transposedArrayLiveData = arrayflat[0].map(function (col: any, i: any) {
		return arrayflat.map(function (row) {
			return row[i];
		});
	});
	transposedArrayLiveData.forEach((data: any) => {
		data[0] = new Date(data[0]).toLocaleString();
		data.push(filteredKpis[selectedParameterIndex].measurement);
	});

	const getData = () => {
		let dataArray;

		if (isLiveFeed) {
			dataArray = transposedArrayLiveData;
		} else {
			const formattedArray = tabularData.map(([time, value]: [string, number]) => ({
				Time: new Date(time).toLocaleString(),
				Value: value,
				Measurement: filteredKpis[selectedParameterIndex].measurement,
			}));

			dataArray = formattedArray.map(
				({ Time, Value, Measurement }: { Time: string; Value: number; Measurement: string }) => [
					Time,
					Value,
					Measurement,
				]
			);
		}
		return dataArray;
	};

	const generatePDF = () => {
		let dataArray;
		dataArray = getData();

		const doc = new jsPDF();
		autoTable(doc, {
			head: [["Time", "Value", "Measurement"]],
			body: dataArray,
		});
		doc.save("report.pdf");
	};

	const generateCSV = () => {
		return getData();
	};

	return (
		<div className='mt-2 d-flex flex-row'>
			<section className={`${styles.kpi_list_section}`}>
				<ListNavigation filteredKpis={filteredKpis} />
			</section>
			<section className='w-100'>
				<header className={`d-flex flex-row flex-wrap justify-content-between align-items-baseline`}>
					<section className={`ms-2 d-flex flex-row flex-wrap`}>
						<div className='text-h2'>{filteredKpis[selectedParameterIndex]["IotData"].displayName}</div>
					</section>
					<section className={`d-flex flex-row flex-wrap justify-content-between align-items-center`}>
						<div className={`mx-2 d-flex flex-row flex-wrap align-items-center`}>
							<div className={`me-2`}>
								<input
									type='checkbox'
									name='detailedView_LiveFeed'
									checked={isLiveFeed}
									onChange={() => {
										setIsLiveFeed(!isLiveFeed);
									}}
									id='checkbox_01'
								/>
								<label htmlFor='checkbox_01'>Live Feed</label>
							</div>
							<div>
								<IxInputGroup
									onClick={() => {
										if (!isLiveFeed) setEnableDatePicker(!enableDatePicker);
										return true;
									}}>
									<DateTimePickerInput
										disabled={isLiveFeed}
										type={`startDate`}
										dateTimePlaceholder={`Start date & time`}
										setDateTime={setStartDateTime}
									/>
									<DateTimePickerInput
										disabled={isLiveFeed}
										type={`endDate`}
										dateTimePlaceholder={`End date & time`}
										setDateTime={setEndDateTime}
									/>
								</IxInputGroup>
							</div>
						</div>
						<div className={`ms-2`}>
							<IxSplitButton label='Export as' splitIcon='chevron-down-small' placement='auto' outline>
								<IxSplitButtonItem
									label='PDF document'
									icon='pdf-document'
									onClick={(event) => generatePDF()}></IxSplitButtonItem>
								<CSVLink
									style={{ textDecoration: "none ", color: "black" }}
									data={generateCSV()}
									headers={["Time", "Value", "Measurement"]}
									filename={"exportData.csv"}>
									<IxSplitButtonItem label='Data XLSX' icon='xls-document'></IxSplitButtonItem>
								</CSVLink>
							</IxSplitButton>
						</div>
					</section>
				</header>
				<section>
					<div className={`ms-2 d-flex flex-row flex-wrap justify-content-start`}>
						<Card data={statData} />
					</div>
					<div className={`ms-2 mt-2`}>
						<div className={`d-flex flex-row justify-content-between align-items-center`}>
							<div className='d-flex align-items-center'>
								<div className='btn-group'>
									<IxIconButton class='m-1' icon='monitor-trend'
										variant={graphView ? "Primary" : "Secondary"}
										onClick={() => { setGraphView(true); setSummaryView(false) }}></IxIconButton>
									<IxIconButton class='m-1' icon='table'
										variant={!graphView && !summaryView ? "Primary" : "Secondary"}
										onClick={() => setGraphView(false)}></IxIconButton>
									<IxIconButton class="m-1" icon='ai'
										variant={summaryView ? "Primary" : "Secondary"}
										onClick={() => { setGraphView(false); setSummaryView(true); }}></IxIconButton>
								</div>
							</div>
							<div className={`me-2`}>
								{!graphView && !summaryView && (
									<IxExpandingSearch
										onValueChange={(event) => setSearchText(event.target.value)}
										placeholder='Search KPI values'></IxExpandingSearch>
								)}
							</div>
						</div>
						{graphView ? (
							<TimeSeriesChart ref={LineChartRef} initialData={[{ ...initialChartData }]} />
						) : !summaryView && (
							<Tabulardata
								data={filteredKpis[selectedParameterIndex]}
								searchText={searchText}
								tabularData={tabularData}
							/>
						)}
						<div className={`m-2`}>
							{summaryView && !graphView && <OpenAIAnalyzer data={{ ...initialChartData }}></OpenAIAnalyzer>}
						</div>
						<div key='chatAssist' className={`${styles.chatAssist}`}>
							<IxIcon name="notifications-filled" size="32" onClick={() => setDrawerOpen(true)}></IxIcon>
						</div>

					</div>
				</section>
				{/* Chat Drawer */}
				<IxDrawer fullHeight={true} show={isDrawerOpen} width={28} onDrawerClose={() => setDrawerOpen(false)}>
					<div className={`d-flex flex-column`}>
						<header className={`text-l-title mx-3 my-2`}>AI Assistant</header>
						<IxDivider></IxDivider>
						<section className={styles.chatContainer}>
							<div key="chatContent">
								<div key="chatContent" className={styles.chatHistory} ref={chatHistoryRef}>
									{chatHistory.map((chat, index) => (
										<div key={index} className={chat.sender === 'You' ? styles.userMessage : styles.systemMessage}>
											<strong>{chat.sender}:</strong>&nbsp;
											{
												chat.sender === 'You'
													? (<span key={`chat_${index}`}>
														{chat.message}
													</span>)
													: (
														<div key={`systemMessage_${index}`} style={{ display: 'block', height: 'auto', overflow: 'hidden' }}>
															<ReactMarkdown key={`systemResponse_${index}`}>{chat.message}</ReactMarkdown>
														</div>
													)
											}

										</div>
									))}
								</div>
								<div key="inputField" className={`${styles.inputField}`}>
									<input
										type="text"
										className={`${styles.chatInput} form-control`}
										value={userQuery}
										onChange={(e) => setUserQuery(e.target.value)}
										onKeyDown={handleKeyPress}
										placeholder="Type your message..."
									/>
									<IxIcon name="arrow-up" size="32" title="Send" onClick={handleSendQuery} className={`${styles.sendIcon}`}></IxIcon>
								</div>
							</div>
						</section><br />
					</div>
				</IxDrawer>
			</section>
		</div>
	);
};

export default Detailedview;
