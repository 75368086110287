import styles from './Card.module.scss';
import {IxTile} from "@siemens/ix-react";

const Card = ({ data }: { data: any }): JSX.Element => {
  let statData = [
    { name: "Lower limit", value: data.lowerLimit},
    { name: "Upper limit", value: data.upperLimit },
    { name: "Minimum value", value: data.min },
    { name: "Maximum value", value: data.max },
    { name: "Average value", value: data.avg},
    { name: "Total data points", value: data.count },
  ]

  if(data.timeseries.length) {
    const [max, min, sum] = data.timeseries.reduce((acc:any, val:any) => {
      acc[0] = Math.max(acc[0], val);
      acc[1] = Math.min(acc[1], val);
      acc[2] += val;
      return acc;
    }, [-Infinity, Infinity, 0]);
    const count = data.timeseries.length;
    const avg = Number((sum/count).toFixed(3));

    statData[2].value = min;
    statData[3].value = max;
    statData[4].value = avg;
    statData[5].value = count;
    
  }

  return (
    <>
      {
        statData.map((el) => {
          return (
            <IxTile key={el.name} className={`${styles.kpi_card} my-2 me-2`} size="medium">
              <div slot="header">{el.name}</div>
              <div className="text-l color-warning">{el.value}</div>
            </IxTile>
          )
        })
      }
    </>
  );
};

export default Card;
