import React, { useState, useEffect } from 'react';
import { Route, Navigate, Routes, useLocation, useNavigate } from 'react-router-dom';
import SideBar from "../Sidebar/Sidebar";
import Dashboard from "../../Dashboard/Dashboard";
import UserManagement from "../../UserManagement/UserManagement";
import Organization from "../../UserManagement/Organization/Organization";
import Settings from '../../Settings/settings';
import Kpis from "../../Components/Kpis/Kpis";
import KPIView from "../../KPIView/KPIView";
import Alarms from "../../Components/Alarms/Alarms";
import Maps from "../../Mapview/Maps";
import { ManagementType, OrgManagementActions, PlantManagementActions, RolesEnum, UserManagementActions } from "../../Config/enums/RolesEnum";
import styles from './TopBar.module.scss';
import { IxBasicNavigation } from '@siemens/ix-react';
import { useInject } from '../../hooks/useInject';
import RolesService from '../../Services/RolesService';
import { useAppSelector } from '../../redux/hooks';
import Spinner from '../../Shared/Spinner/Spinner';

const TopBar = () => {
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const loggedInUserRoles = useInject(RolesService).getLoggedInUserRole();
  const loggedInUserPermissions = useAppSelector(state => state.roleSlice.loggedInUserPermissions);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate loading permissions
    const loadPermissions = async () => {
      // Simulate an async operation to load permissions
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setPermissionsLoaded(true);
    };

    loadPermissions();
  }, []);

  useEffect(() => {
    // Check if there is a URL fragment and clean it up
    if (location.hash) {
      const cleanUrl = location.pathname + location.search;
      navigate(cleanUrl, { replace: true });
    }
  }, [location, navigate]);

  if (!permissionsLoaded) {
    return <Spinner></Spinner>; // Render a loading indicator while permissions are being loaded
  }

  return (
    <IxBasicNavigation className="header-background" applicationName="STP Analytics">
      <div className="placeholder-logo" slot="logo">
        <div className={`${styles.logo_color}`}>SIEMENS</div>
      </div>
      <SideBar />
      <div className={`w-100`}>
        <Routes>
          <Route path="/" element={<Maps />}></Route>
          <Route path="/Maps" element={<Maps />}></Route>
          {loggedInUserPermissions[ManagementType.Plant][PlantManagementActions.View] && (
            <>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="dashboard/:idd/:id" element={<KPIView />}></Route>
              <Route path="/kpis/:plantId" element={<Kpis />}></Route>
              <Route path="/Alarms" element={<Alarms />}></Route>
            </>
          )}
          {loggedInUserPermissions[ManagementType.User][UserManagementActions.View] && (
            <Route path="/userManagement" element={<UserManagement />}></Route>
          )}
          {loggedInUserPermissions[ManagementType.Org][OrgManagementActions.View] && (
            <Route path="/Organization" element={<Organization />}></Route>
          )}
          {loggedInUserRoles.includes(RolesEnum.admin) && (
            <Route path="/settings" element={<Settings />}></Route>
          )}
          <Route path="*" element={<Navigate to="/" replace />}></Route>
        </Routes>
      </div>
    </IxBasicNavigation>
  );
};

export default TopBar;